 import Header from './Header/index';
 import WelcomeSection from './WelcomeSection';
 import CompanySection from './CompanySection';
 import HistorySection from './HistorySection/index';
 import AwardsSection from './AwardsSection';
 import TalentsSection from './TalentsSection';
 import BairroSection from './BairroSection';
 import SustentabilitySection from './SustentabilitySection';
 import QualitySection from './QualitySection/index';
 import NewsSection from './NewsSection/index';
 import ContactSection from './ContactSection/index';
 import FooterSection from './FooterSection';
     
 export default {
     Header,
     WelcomeSection,
     CompanySection,
     HistorySection,
     AwardsSection,
     TalentsSection,
     BairroSection,
     SustentabilitySection,
     QualitySection,
     NewsSection,
     ContactSection,
     FooterSection
 }

