<template>
  <li class="sub-menu">
    <a @click="open">
      <slot name="main"/>
    </a>
    <ul @blur="onBlur" @click="onBlur" id="sub-menu" v-if="isSubMenuOpen" class="sub-menu-list -light-shadow">
      <slot class="item" name="menu" />
    </ul>
  </li>
</template>

<script>
  export default {
    data() {
      return {
        isSubMenuOpen: false
      }
    },
    methods: {
      open() {
        this.isSubMenuOpen = !this.isSubMenuOpen
      },
      onBlur(e) {
        if(e.target.id === 'sub-menu') {
          this.isSubMenuOpen = false
        }
      }
    },
  }
</script>

<style lang="css" scoped>
  .sub-menu {
      float: left;
      font-size: 15px;
      padding: 10px 0px 10px 0px;
   }

  .sub-menu li{
    float: none;
    width: fit-content;
  }

  .sub-menu-list {
    background-color: #fff;
    border-radius: 3px;
    font-size: 10px;
    list-style: none;
    margin-top: 12px;
    opacity: 0.95;
    padding: 10px 0px 10px 18px;
    position: absolute;
    width: 200px;
  }

   .sub-menu a {
     color: black;
     padding: 5px 10px 5px 10px;
     text-decoration: none;
     transition: all 0.2s ease;
   }
   .sub-menu a:hover, .sub-menu a:focus {
     color: #3B00B8;
     cursor: pointer;
   }

   @media screen and (max-width: 900px) {
     .sub-menu {
        float: left;
        font-size: 15px;
        padding: 0px 0px 10px 0px;
      }

      .sub-menu + .sub-menu, li + .sub-menu {
        padding: 10px 0px 10px 0px;
      }

     .sub-menu-list {
        display: flex;
        flex-flow: column;
        position: relative;

        font-size: 10px;
        height: 150px;
        width: 100%;
        margin-top: 5px;
        padding: 10px 0px 10px 18px;

        background-color: white;
        border-bottom: 1px gray dotted;
        border-top: 1px gray dotted;
        border-radius: 3px;
        list-style: none;
        opacity: 0.95;
      }

   }
</style>