<template>
<div id="Header">
  <NavMenu>
    <SubMenu>
      <template v-slot:main>
        {{ $t("header.menu.who-we-are") }}
        &#9662;
      </template> 
      <template v-slot:menu>
        <NavItem href="#CompanySection" :subMenu="true">{{ $t("header.sub-menu.the-company") }}</NavItem>
        <NavItem href="#HistorySection" :subMenu="true">{{ $t("header.sub-menu.our-history") }}</NavItem>
        <NavItem href="#AwardsSection"  :subMenu="true">{{ $t("header.sub-menu.awards") }}</NavItem>
        <NavItem href="#TalentsSection" :subMenu="true" >{{ $t("header.sub-menu.our-talents") }}</NavItem>
        <NavItem href="#BairroSection" :subMenu="true">{{ $t("header.sub-menu.300-anos-neighborhood") }}</NavItem>
      </template>
    </SubMenu>
    <NavItem href="#SustentabilitySection">{{ $t("header.menu.sustentability") }}</NavItem>
    <NavItem href="#QualitySection">{{ $t("header.menu.quality") }}</NavItem>
    <NavItem href="#NewsSection">{{ $t("header.menu.news") }}</NavItem>
    <NavItem href="#ContactSection">{{ $t("header.menu.contact") }}</NavItem>
  </NavMenu>
  <a class="top-button" href="#Header">
      <img src="@/assets/img/icons/arrow-up.png" :alt="$t('header.alts.top')">
  </a>
</div>
</template>

<script>
  import components from './components'
  const {NavItem, SubMenu, NavMenu} = components

  export default {
    name: "Header",
    components: {
      NavItem,
      SubMenu,
      NavMenu
    },
    data() {
      return {
        isDesktop: window.innerWidth > 900,
      }
    },
    created() {
      window.addEventListener("resize", this.activateBurgerMenu);
    },
    destroyed() {
      window.removeEventListener("resize", this.activateBurgerMenu);
    },
    methods: {
      activateBurgerMenu(e) {
        this.isDesktop = e.target.innerWidth > 900
      }
    }
  }
</script>

<style lang="css" scoped>
  #Header {
    background-color: #474747;
    height: 90px;
  }

  .top-button {
    position: fixed;
    background-color: var(--blue);
    bottom: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
  }

  .top-button:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 1010px) {
    .top-button {
      bottom: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
      border-radius: 5px;
    }
  }

  @media screen and (max-width: 900px) {
    #Header {
      height: 70px;
    }

    .top-button {
      bottom: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      border-radius: 5px;
    }

  }

</style>
