<template>
	<li 
		:style="style" 
		:class="subMenu && 'sub-menu-item'"
		class="nav-item">
		<a v-bind="$attrs" :href="href">
			<slot />
		</a>
	</li>
</template>

<script>
export default {
	props: {
		href: {
			type: String,
			default: "",
		},
		padding: {
			type: String,
			default: '5px 10px 5px 10px'
		},
		subMenu: {
			type: Boolean,
			default: false
		},
		first: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		style() {
			return {
				//padding: this.padding
			}
		}
	},
};
</script>

<style lang="css" scoped>
.nav-item {
	float: left;
	font-size: 15px;
	padding: 5px 10px 5px 10px;
}

.nav-item a {
	color: black;
	padding: 10px 0;
	text-decoration: none;
	transition: all 0.2s ease;
}
.nav-item a:hover,
.nav-item a:focus {
	color: #3b00b8;
	cursor: pointer;
}


@media screen and (max-width: 900px) {
	.nav-item {
		padding: 10px;
	}

	.sub-menu-item {
		padding: 0px 5px 5px 5px;
	}

	.sub-menu-item + .sub-menu-item {
		padding: 5px;
	}

}
</style>