<template>
  <Section id="ContactSection" :title="$t('contact-section.title')" img="./img/backgrounds/bg-contato.jpg">
    <div class="form-wrapper">
      <div class="form-header ">
        <button @click="() => selectForm('resumee')" :class="selectedForm === 'resumee' && 'selected-form'">{{  $t('contact-section.forms.resumee') }}</button>
        <button @click="() => selectForm('contact')" :class="selectedForm === 'contact' && 'selected-form'">{{  $t('contact-section.forms.contact') }}</button>
      </div>
      <div class="form-body">
        <ResumeeForm v-if="selectedForm === 'resumee'"/>
        <ContactForm v-else-if="selectedForm === 'contact'" />
      </div>
    </div>

    <div class="contact-wrapper">
      <div class="map-wrapper">
        <Map />
      </div>
      <ContactCard />
    </div>
  
  </Section>
</template>

<script>
  import components from '@/components/global';
  import ResumeeForm from './forms/ResumeeForm';
  import ContactForm from './forms/ContactForm';
  import Map from './Map';
  import ContactCard from './ContactCard'

  const { Section } = components;

  export default {
    components: {
      Section,
      ResumeeForm,
      ContactForm,
      Map,
      ContactCard
    },
    data() {
      return {
        selectedForm: "resumee"
      }
    },
    methods: {
      selectForm(form) {
        this.selectedForm = form;
      }
    },
  }
</script>

<style lang="css" scoped>
  .form-wrapper {
    width: 95%;
    margin: auto;
    border-radius: 15px;
    box-shadow: 0px 3px 5px rgba(0,0,0,0.5);
  }

  .form-header button {
    height: 50px;
    width: 50%;
    z-index: 1;
    border-radius: 15px 15px 0px 0px;
    box-shadow: none;
    border: 0.3px groove lightgray;
    background-color: rgba(240, 240, 240, 0.8);
    transition: background-color 0.5s ease;
    font-size: 14px;
  }
  .form-header button:hover {
    background-color: rgba(250, 250, 250, 0.8);
  }

  .form-body {
    border-radius: 0px 0px 15px 15px;
    background-color: rgba(255, 255, 255, 0.8);
    height: fit-content;
    overflow: auto;
  }

  .form-header .selected-form {
    border-bottom: 0px;
    background-color: transparent;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .contact-wrapper {
    margin: auto;
    margin-top: 30px;
    width: 95%;
    height: 300px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 3px 5px rgba(0,0,0,0.5);
  }

  .map-wrapper {
    float: left;
    width: 75%;
    height: 100%;
  }


@media screen and (max-width: 1200px) {
  .contact-wrapper {
    height: 310px;
  }
}

@media screen and (max-width: 900px) {
  .contact-wrapper {
    height: fit-content;
  }

  .map-wrapper {
    width: 100%;
    margin-bottom: 40px;
    height: 200px;
  }

}

</style>