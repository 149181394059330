<template>
  <div class="Card -dark-shadow">
    <Panel v-if="img.length > 0" class="panel" width="98%" :img="img" :alt="alt" />
    <div class="wrapper">
     <p class="year">{{ year }}</p>
     <p class="event"> <slot name="event"/> </p>
     <hr />
     <p class="text"> <slot name="text"/> </p>
    </div>
  </div>
</template>

<script>
  import components from '@/components/global'
  const { Panel } = components
  export default {
    components: {
      Panel,
    },
    props: {
      img: {
        type: String,
        default: ""
      },
      alt: {
        type: String,
        default: ""
      },
      year: {
        type: String,
        default: ""
      },
      event: {
        type: String,
        default: ""
      },
      text: {
        type: String,
        default: ""
      }
    }
  }
</script>

<style lang="css" scoped>
  .Card {
    background-color: white;
    margin: auto;
    margin-top: 15px;
    padding: 8px;
    width: 98%;
  }

  .panel {
    margin: auto;
    margin-top: 20px;
  }

  hr {
    border-top: 1px dotted lightgray;
    border-bottom: 0;
    margin-top: 13px;
  }

  p {
    line-height: 30px;
    margin: auto;
    text-align: center;
  }

  .year,
  .event {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
  }

  .year {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
  }

  .event {
    font-size: 18px;
    margin-top: 8px;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 13px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 800px) {
    p {
      line-height: 15px;
    }

  .year {
    font-size: 20px;
  }

  .event {
    font-size: 16px;
    line-height: 18px;
    margin-top: 12px;
  }

}
</style>