<template>
	<form @submit="handleSubmit" class="Form">
		<h3 class="title">{{ $t("contact-section.forms.resumee") }}</h3>
		<div class="input-wrapper">
			<InputField
				required
				v-model="form.name"
				:label="$t('contact-section.forms.name')"
			/>
			<InputField
				required
				type="email"
				v-model="form.email"
				:label="$t('contact-section.forms.email')"
			/>
		</div>
		<div class="input-wrapper">
			<InputField
				required
				v-model="form.role"
				:label="$t('contact-section.forms.role')"
			/>
			<InputField v-model="form.file" type="file" />
		</div>
		<div class="submit-wrapper">
			<Button :disabled="sending" width="100px" propClass="submit">{{
				$t("contact-section.forms.submit")
			}}</Button>
		</div>
		<p v-if="notFileError" class="error">
			{{ $t("contact-section.forms.not-file-error") }}
		</p>
		<p v-else-if="error" class="error">
			{{ $t("contact-section.forms.error") }}
		</p>
		<p v-else-if="success" v-appear class="success">
			{{ $t("contact-section.forms.success") }}
		</p>
		<p v-if="sending" class="sending">
			{{ $t("contact-section.forms.sending") }}
		</p>
	</form>
</template>

<script>
import components from "@/components/global";
const { InputField, Button } = components;

import gql from "graphql-tag";

export default {
	components: {
		InputField,
		Button,
	},
	data() {
		return {
			error: false,
			success: false,
			sending: false,
			notFileError: false,
			form: {
				name: "",
				email: "",
				role: "",
				file: null,
			},
		};
	},
	methods: {
		async handleSubmit(e) {
			e.preventDefault();
			const { name, email: from, role, file } = this.form;

			if (file === null) {
				this.notFileError = true;
				return;
			}

			this.success = false;
			this.error = false;
			this.notFileError = false;
			this.sending = true;

			try {
				await this.$apollo.mutate({
					mutation: gql`
						mutation(
							$from: String!
							$role: String!
							$name: String!
							$file: Upload!
						) {
							sendMail(
								type: "resumee"
								data: {
									from: $from
									to: "cv"
									name: $name
									role: $role
									file: $file
								}
							)
						}
					`,
					variables: {
						from,
						name,
						role,
						file,
					},
					context: {
						useMultipart: true,
					},
				});
			} catch (err) {
				this.error = true;
				this.sending = false;
				return;
			}
			this.success = true;
			this.sending = false;
		},
	},
};
</script>

<style lang="css" scoped>
</style>