import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from  '@apollo/client/core'
import { InMemoryCache } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from '@apollo/client/link/core';

Vue.use(VueApollo)

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(), 
  link: ApolloLink.from([
    createUploadLink({
      headers: { 'datasource': 'fundimisa' },
      uri: process.env.VUE_APP_BACKEND_HTTP,
    })
  ]),
})

const apolloProvider =  new VueApollo({
  defaultClient: apolloClient
})

export default apolloProvider
