<template>
  <Section id="TalentsSection" :title="$t('talents-section.title')" img="./img/backgrounds/bg-humans.jpg">
      <div v-appear class="col first">
        <Panel v-right class="img" width="95%" img="./img/talents/ingles.jpg" :alt="$t('talents-section.alts.english')"/>
        <p style="margin-bottom: 0px;" v-html="$t('talents-section.p-1')"></p>
      </div>
       <div v-appear class="col second">
        <p v-html="$t('talents-section.p-2')" style="margin-top: 0;"></p>
        <Panel v-left class="img" width="95%" img="./img/talents/capacitacao.jpg" :alt="$t('talents-section.alts.english')"/>
        <p v-html="$t('talents-section.p-3')" style="margin-bottom: 0;" />
      </div>
  </Section>
</template>

<script>
  import components from '@/components/global';
  const {Section, Panel} = components;

  export default {
    components: {
      Section,
      Panel
    },
  }
</script>

<style lang="css" scoped>
  #TalentsSection .col {
    float: left;
    width: 50%;
  }

  #TalentsSection p {
    width: 95%;
  }

  @media screen and (max-width: 900px) {
    #TalentsSection .col {
      float: none;
      margin-top: 0;
      width: 100%;
    }

    #TalentsSection .col.first {
      margin-top: 30px;
    }

    #TalentsSection p {
    width: 100%;
  }
  }
  
</style>