<template>
  <button v-bind="$attrs" :class="propClass" :style="{ width: width }" @click="onClick" class="Button">
    <slot />
  </button>
</template>

<script>
  export default {
    methods: {
      onClick() {
        this.$emit('click')
      }
    },
    props: {
      width: {
        type: String,
        default: "150px"
      },
      propClass: {
        type: String,
        default: ''
      }
    },
  }
</script>

<style lang="css" scoped>
.Button {
  border: 1px solid var(--blue);
  bottom: 15px;
  padding: 10px;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  background-color: var(--blue);
  transition: all 0.5s ease;
  border-radius: 5px;
}

.Button:hover, .Button:focus {
  background-color: white;
  color: var(--blue);
  cursor: pointer;
}

.Button:disabled {
  background-color: gray;
  color: white;
}

.Button:disabled:focus, .Button:disabled:hover {
  cursor: default;
}
</style>