<template>
  <Section id="SustentabilitySection" :title="$t('sustentability-section.title')" img="./img/backgrounds/bg-sustentabilidade.jpg">
    <p v-appear v-html="$t('sustentability-section.intro')" />
    <div>
      <div class="inline left">
        <h3 v-grow v-html="$t('sustentability-section.waste-treatment.title')"/>
        <p v-appear v-html="$t('sustentability-section.waste-treatment.body')"/>
      </div>
      <div class="inline">
        <h3 v-grow v-html="$t('sustentability-section.solid-waste.title')"/>
        <p v-appear v-html="$t('sustentability-section.solid-waste.body')"/>
      </div>
    </div>

    <div class="atmos">
      <h3 v-grow v-html="$t('sustentability-section.atmospheric-emissions.title')" />
      <div  class="img">
      <Panel v-right img="./img/sustentability/ar2.jpg" :alt="$t('sustentability-section.alts.exhaust')" /> 
      </div>
      <p v-appear v-html="$t('sustentability-section.atmospheric-emissions.body')"/>
    </div>
    <div class="clearfix" />
    <div class="objectives-target">
      <h3 v-grow>
        {{ $t('sustentability-section.objectives-targets.title') }}
      </h3>
      <div class="">
        <h4 v-grow>
        > {{ $t('sustentability-section.objectives-targets.objective-1.title') }}
        </h4>
        <p v-appear v-html="$t('sustentability-section.objectives-targets.objective-1.body')"/>
      </div>
      <div class="afluente">
        <h4 v-grow>
        > {{ $t('sustentability-section.objectives-targets.objective-2.title') }}
        </h4>
        <p v-appear v-html="$t('sustentability-section.objectives-targets.objective-2.body')"/>

        <div class="inline">
          <div class="img">
            <Panel v-right img="./img/sustentability/afluentes.png" :alt="$t('sustentability-section.alts.exhaust')" /> 
          </div>
        </div>
        <div class="inline">
          <div class="img2">
            <Panel v-right img="./img/sustentability/afluentes-2.png" :alt="$t('sustentability-section.alts.exhaust')" /> 
          </div>
        </div>
        
      </div>
    </div>
  </Section>
</template>

<script>
import components from '@/components/global';
const { Section, Panel} = components;

export default {
  name: "SustentabilitySection",
  components: {
    Section,
    Panel
  },
  data() {
    return {
      isDesktop: window.innerWidth > 800,
    }
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize(e) {
      this.isDesktop = e.target.innerWidth > 800
    }
  }
}
</script>

<style lang="css" scoped>

  #SustentabilitySection p {
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;
  }

  #SustentabilitySection .inline {
    float: left;
    width: 50%;
  }

  #SustentabilitySection .inline.left p {
    margin-right: 20px;
  }

  #SustentabilitySection .objectives-target {
    float: left;
    margin-top: 20px;
  }

  #SustentabilitySection .img {
    width: 100%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  #SustentabilitySection div h3 {
    margin: 0;
    font-weight: 100;
    font-size: 26px;
    font-family: 'Raleway', sans-serif;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: 1px dashed #d5d5d5;
  }

  #SustentabilitySection div h4 {
    margin: 0;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;
    margin: 30px 0 0 0;
  }

  #SustentabilitySection .objectives-target p {
    margin-left: 0 0 20px 20px;

  }

  #SustentabilitySection .atmos .img  {
    float: left;
    width: 20%;
  }

  #SustentabilitySection .atmos p {
    margin-top: 20px;
  }



  #SustentabilitySection .afluente p {
    margin-top: 30px;
  }

  #SustentabilitySection .afluente .img  {
    margin-top: 0 !important;
    float: left;
    width: 70%;
  }

  #SustentabilitySection .afluente .img2  {
    margin-top: 0 !important;
    float: right;
    width: 45%;
  }

  @media screen and (max-width: 900px) {
    #SustentabilitySection .inline {
      float: none;
      width: 100%;
    }

    #SustentabilitySection .inline.left p {
      margin-right: 0px;
    }

    #SustentabilitySection .atmos .img  {
      margin-left: auto;
      margin-right: auto;
      float: none;
      width: 70%;
    }

    #SustentabilitySection .afluente .img  {
      margin-left: auto;
      margin-right: auto;
      float: none;
    }

    #SustentabilitySection .afluente .img2  {
      margin-left: auto;
      margin-right: auto;
      float: none;
      padding-top: 15px;
    }
  }
</style>