<template>
  <div  @click="fakeBlur" :id="id" class="PopUp">
    <transition v-move appear name="toggle">
      <div v-if="!closing" class="content">
        <h1 class="title">{{ title }}</h1>
        <img @click="close" class="exit" src="@/assets/img/icons/clear.svg" alt="Exit" />
        <div class="slot-wrapper">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ""
      },
      id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        closing: false
      }
    },
    methods: {
      close() {
        this.closing = true
        setTimeout(() => {
          this.$emit('closed', '')
        }, 400)
      },
      fakeBlur(e) {
        if(e.target === document.getElementById(this.id)) {
          this.close()
        }
      }
    },
  }
</script>

<style lang="css" scoped>
  .PopUp {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  }

  .PopUp .content {
    background-color: white;
    width: 50%;
    height: 75%;
    border-radius: 5px;
    padding: 20px;
  }

  .PopUp .title {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    letter-spacing: 1.2px;
    float: left;
  }

  .PopUp .exit {
    float:right;
    margin-left: auto;
    padding-left: 20px;
    padding-bottom: 20px;
  }
  .PopUp .exit:focus, .PopUp .exit:hover  {
    cursor: pointer;
    opacity: 0.7;
  }

  .PopUp .content div {
    margin-top: 50px;
  }

   .PopUp .slot-wrapper {
     height: 90%;
     width: 100%;
     overflow-y: scroll;
     overflow-x: hidden;
   }

  @media screen and (max-width: 900px) {
    .PopUp .content {
      width: 95%;
      height: 98%;
      border-radius: 5px;
      padding: 20px;
    }
  }
</style>