<template>
  <nav class="NavBar -light-shadow">
    <img class="logo" src="@/assets/img/logo/logofund.svg" :alt=" () => $t('nav-bar.alts.logo') " />
    <div v-if="isDesktop" class="panel-wrapper">
      <ul class="nav-list">
        <slot />
      </ul>
      <LangButton />
    </div> 
    <BurgerMenu v-else >
      <slot />
    </BurgerMenu>
  </nav>
</template>

<script>
  import LangButton from './LangButton'
  import BurgerMenu from './BurgerMenu'

  export default {
    name: "NavMenu",
    components: {
      LangButton,
      BurgerMenu
    },
    data() {
      return {
        isDesktop: window.innerWidth > 900,
      }
    },
    created() {
      window.addEventListener("resize", this.activateBurgerMenu);
    },
    destroyed() {
      window.removeEventListener("resize", this.activateBurgerMenu);
    },
    methods: {
      activateBurgerMenu(e) {
        this.isDesktop = e.target.innerWidth > 900
      }
    }
  }
</script>

<style lang="css" scoped>
  .NavBar {
    background-color: #ffffff;
    display: flex;
    height: 54px;
    justify-content: space-between;
    opacity: 0.9;
    padding-left: 12%;
    padding-right: 12%;
    position: fixed;
    width: 100%;
    z-index: 2;
  }

  .panel-wrapper {
    align-items: center;
    display: flex;
  }

  .nav-list {
    align-items: center;
    display: inline-flex;
    list-style: none;
  }

  .logo {
    max-width: 225px;
    transition: opacity 0.2s ease;
    width: 150px;
  }
  .logo:focus, .logo:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  @media screen and (max-width: 1010px) {
    #NavBar {
      padding-left: 2%;
      padding-right: 2%;
    }

  }
  @media screen and (max-width: 900px) {
    .NavBar {
      justify-content: center;
      height: 40px;
    }

    .logo {
      width: 100px;
    } 
  }

</style>
