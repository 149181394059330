<template>
  <div :style="{width: width}" class="Panel -dark-shadow">
    <img :src="img" :alt="alt">
  </div>
</template>

<script>
  export default {
    props: {
      img: {
        type: String,
        default: ""
      },
      alt: {
        type: String,
        default: "Image"
      },
      width: {
        type: String,
        default: '95%'
      }
    },
  }
</script>

<style lang="css" scoped>
  .Panel {
    padding: 8px;
  }

  .Panel img{
    width: 100%;
  }
</style>