<template>
<div class="Map">
    <iframe scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Fundimisa%20Fundi%C3%A7%C3%A3o%20e%20Usinagem%20Santo%20%C3%82ngelo+(Fundimisa%20Fundi%C3%A7%C3%A3o%20e%20Usinagem)&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="520" height="400" frameborder="0"></iframe> 
    <a href='http://maps-generator.com/pt'>maps-generator.com</a>
</div>
</template>

<script>

export default {
  name: 'Map',
  data() {
    return {
      locations: [
        {
          position: {
            lat: -28.275253871261207, 
            lng: -54.24283226531983
          }
        }
      ],
      icon: './img/icons/map-marker.png'
    }
  },
  async mounted() {
      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.src = `https://maps-generator.com/google-maps-authorization/script.js?id=55c477c05fb47022051e0c0d592abeaf44f8ea68`;
      document.querySelector('head').appendChild(script);
  },
};
</script>

<style>
.Map {
  width: 100%;
  height: 100%;
}

.Map * {
  width: 100%;
  height: 100%;
}


</style>