<template>
    <div class="contact">
      <p class="name">Fundimisa Fundição e Usinagem</p>
      <div>
        <hr class="bar">
        <div class="align">
          <img src="@/assets/img/icons/lnr-map-marker.svg" alt="Icone Marcador de Mapa"  />
          <span>Rua Giruá, 50 - Distrito Industrial</span>
        </div>
        <p>Santo Ângelo - RS</p>
        <p>CEP: 98805-540</p>
      </div>
      <div>
        <hr class="bar">
        <div class="align">
          <img src="@/assets/img/icons/lnr-phone-handset.svg" alt="Icone Marcador de Mapa"  />
          <span>(55) 3313-9000 </span>
        </div>
      </div>
      <div>
        <hr class="bar">
        <div class="align">
          <img src="@/assets/img/icons/lnr-envelope.svg" alt="Icone Marcador de Mapa"  />
          <span>comercial@fundimisa.com.br</span>
        </div>
        <p>rh@fundimisa.com.br</p>
      </div>
      <div>
        <hr class="bar">
        <div class="align">
          <img src="@/assets/img/icons/lnr-license.svg" alt="Icone Marcador de Mapa"  />
          <span>CNPJ: 07.032.076/0001-48</span>
        </div>
        <p>Inscrição Estadual: 113/0136342 </p>
      </div>
    </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="css" scoped>
  .contact {
    width: 25%;
    float: left;
  }

  .contact .name {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
    color: var(--blue);
    font-weight: 700;
  }

  .contact .bar {
    width: 90%;
    border-top: 1px solid black;
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .contact div p {
    font-size: 12px;
    margin: 0;
    margin-left: 28px;
    width: fit-content;
    color: gray;
  }

  .contact div span {
    font-size: 12px;
    margin-left: 3px;
    color: gray;
  }

  .contact img {
    width: 16px;
    margin-left: 10px;
  }

  .contact .align {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    .contact .name {
      font-size: 12px;
      line-height: 16px;
    }

    .contact div p {
      font-size: 10px;
      line-height: 16px;
    }

    .contact div span {
      font-size: 10px;
      line-height: 16px;
    }

    .contact img {
      width: 12px;
    }
  }

  @media screen and (max-width: 900px) {
    .contact {
      width: 100%;
      float: none;
      padding: 20px;
    }

    .contact .name {
      font-size: 14px;
      line-height: 14px;
    }

    .contact div p {
      font-size: 14px;
      line-height: 16px;
    }

    .contact div span {
      font-size: 14px;
      line-height: 16px;
    }

    .contact img {
      width: 16px;
    }
  }
</style>