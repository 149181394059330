<template>
	<div :style="backgroundStyle" class="section">
		<div :style="wrapperStyle" class="wrapper -dark-shadow">
			<div class="wrapper-head">
				<h2 v-appear class="title">{{ title }}</h2>
				<hr v-grow class="break" />
				<p v-if="subtitle.length > 0" class="subtitle">{{subtitle}}</p>
				<p class="margin-bottom"></p>
			</div>
			<div>
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "The Title",
		},
		subtitle: {
			type: String,
			default: ""
		},
		img: {
			type: String,
			default: "",
		},
		opacity: {
			type: String,
			default: "0.7",
		},
		width: {
			type: String,
			default: "78%"
		}
	},
	computed: {
		backgroundStyle() {
			return {
				background: "url(" + this.img + ") repeat fixed",
			};
		},
		wrapperStyle() {
			return {
				"background-color": "rgba(255, 255, 255, " + this.opacity + ")",
				width: this.isDesktop ? this.width : '95%'
			};
		},
	},
	data() {
    return {
      isDesktop: window.innerWidth > 900,
    }
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize(e) {
      this.isDesktop = e.target.innerWidth > 900
    }
  }
};
</script>

<style lang="css" scoped>
.section {
	background-attachment: fixed;
	background-repeat: repeat;
	display: flex;
	justify-content: center;
	overflow: visible;
	padding-top: 15px;
	padding-bottom: 15px;
	width: 100%;
}

.wrapper {
	background-color: rgba(255, 255, 255, 0.6);
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 25px;
}

.wrapper .title {
	color: #333;
	font-family: "Raleway", sans-serif;
	font-size: 42px;
	font-weight: 100;
	margin: auto;
	margin-top: 70px;
	text-transform: uppercase;
	width: fit-content;
	text-align: center;
}

.wrapper .break {
	border-top: 2px solid #346afe;
	margin-top: 15px;
	margin-left: auto;
	margin-right: auto;
	width: 70px;
}

.wrapper .subtitle {
  margin: auto;
  margin-top: 25px;
  width: fit-content;
}

.wrapper .margin-bottom {
	margin-bottom: 70px;
}

@media screen and (max-width: 1010px) {
	.wrapper {
		padding-left: 15px;
		padding-right: 15px;
		width: 90%;
	}
}
@media screen and (max-width: 900px) {
	.wrapper {
		width: 95%;
	}

	.wrapper .title {
		font-size: 24px;
		margin-top: 40px;
	}

	.wrapper .break {
		border-top: 0.5px solid #346afe;
		margin-top: 5px;
		width: 50px;
	}

	.wrapper .margin-bottom {
		margin-bottom: 40px
	}
}
</style>