<template>

  <Card v-if="!isDesktop && float === 'left'" v-right :year="year" :img='img' :alt='alt'>
    <template v-slot:event >
      <slot name="event" />
    </template>
    <template v-slot:text>
      <slot name="text" />
    </template>
  </Card>

  <Card v-else-if="!isDesktop && float === 'right'" v-left :year="year" :img='img' :alt='alt'>
    <template v-slot:event >
      <slot name="event" />
    </template>
    <template v-slot:text>
      <slot name="text" />
    </template>
  </Card>

  <div class="desktop" v-else-if="float === 'left'">
    <Description direction="left" v-right class="left" :year="year">
      <template v-slot:event >
       <slot name="event" />
      </template>
      <template v-slot:text>
        <slot name="text" />
      </template>
    </Description>
    <div  class="ruler" v-grow></div>
    <Panel v-if="img" :img='img' class="panel right" v-slow-left :alt="alt"/>
  </div>

  <div class="desktop" v-else-if="float === 'right'">
    <Panel v-if="img" :img='img' :alt="alt" class="panel left" v-slow-right />
    <div class="ruler" v-grow></div>
    <Description direction="right" v-left class="right" :year="year">
      <template v-slot:event >
       <slot name="event" />
      </template>
      <template v-slot:text>
        <slot name="text" />
      </template>
    </Description>
  </div>

</template>

<script>
import Card from './Card'
import Description from './Description'
import components from '@/components/global'

const { Panel } = components

export default {
  components: {
    Card,
    Description,
    Panel
  },
  props: {
    img: {
      type: String,
      default: ""
    },
    alt: {
      type: String,
      default: ""
    },
    year: {
      type: String,
      default: ""
    },
    float: {
      type: String,
      default: "left"
    }
  },
  data() {
    return {
      isDesktop: window.innerWidth > 900,
    }
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize(e) {
      this.isDesktop = e.target.innerWidth > 900
    }
  }
}
</script>

<style lang="css" scoped>
  .desktop {
    display: grid;
    grid-template-columns: 1fr 3px 1fr;
    height: fit-content;
  }

  .panel {
    margin: auto;
    margin-top: 80px;
  }

  .left {
    grid-column: 1 / 2;
    margin-left: 0;
    margin-top: 80px;
  }

  .right {
    grid-column: 3 / 4;
    margin-right: 0;
    margin-top: 80px;
  }

  .ruler {
    background-color: var(--blue);
    grid-column: 2 / 3;
    height: 100%;
  }
</style>