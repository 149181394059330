<template>

  <div v-if="type === 'file'" class="InputField" >
    <input 
      v-bind="$attrs" 
      :type="type" 
      :placeholder="label" 
      :files="[value]" 
      @input="$emit('input', $event.target.files[0])" 
    >
  </div>

  <select 
    v-else-if="type === 'select'" 
    v-bind="$attrs" 
    class="InputField" 
    :value="value"
    :defaultValue="label" 
    @change="$emit('input', $event.target.value)" 
  >
    <option 
      v-for="option of options" 
      :key="option.value" 
      :value="option.value"
    > 
      {{ option.name }} 
    </option>
  </select>

  <textarea 
    v-else-if="type === 'textarea'" 
    v-bind="$attrs" 
    class="InputField" 
    cols="30" 
    rows="10" 
    :value="value" 
    @input="$emit('input', $event.target.value)" 
    spellcheck="false"
  ></textarea>

  <input 
    v-else 
    v-bind="$attrs" 
    :value="value" 
    @input="$emit('input', $event.target.value)"  
    :placeholder="label" 
    class="InputField" 
    :type="type"
  >
  
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: ""
      },
      value: {
        type: [String, File],
        default: ""
      },
      type: {
        type: String,
        default: "text"
      },
      options: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      handleChange(e) {
        this.$emit('change', e)
      }
    },
  }
</script>

<style lang="css" scoped>
  .InputField {
    height: 50px;
    background-color: white;
    font-size: 18px;
    border: 1px solid gray;
    border-radius: 5px;
    color: gray;
    transition: all 0.5s ease-in-out;
  }
  .InputField:focus {
    border: 1px solid blue;
    box-shadow: 0px 0px 2px 0px #999;
  }

  input.InputField {
    padding-left: 16px;
  }

  div.InputField input[type='file'] {
    display: flex;
    margin-top: 13px;
    height: 30px;
    padding-left: 16px;
    font-size: 14px;
  }

  div.InputField {
    overflow: hidden;
  }

  select.InputField {
    padding-left: 16px;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  }

  textarea.InputField {
    width: 100%;
    height: 220px;
    padding-top: 12px;
    padding-left: 12px;
    resize: none;
  }

</style>