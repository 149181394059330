<template>
  <Section id="CompanySection" img="./img/backgrounds/bg-sobrenos.jpg" :title="$t('company-section.title')">
    <div class="grid-wrapper">
      <div v-appear class="description">
        <span class="capitalized">{{ $t("company-section.paragraph.capitalized")}} </span>
        <span v-html="$t('company-section.paragraph.p-1')"></span>
        <br />
        <span v-html="$t('company-section.paragraph.p-2')"></span>
      </div>
      <img v-right class="map -svg-shadow" src="@/assets/img/brasil.png" :alt="() => $t('company-section.alts.map')" />
      <div class="boards">
        <div v-slow-left class="content-box blue first">
            <span v-html="$t('company-section.boards.board-1')"></span>
        </div>
        <div v-right class="content-box second">
            <span v-html="$t('company-section.boards.board-2')"></span>
        </div>
        <div v-left class="content-box third">
            <span v-html="$t('company-section.boards.board-3')"></span>
        </div>
        <div v-right class="content-box blue fourth">
          <span v-html="$t('company-section.boards.board-4')"></span>
        </div>
        <InstitutionalPresentation v-up/>
      </div>
    </div>
  </Section>
</template>

<script>
  import components from '@/components/global';
  import InstitutionalPresentation from './InstitutionalPresentation.vue'
  const { Section } = components;
  
  export default {
    components: {
      Section,
      InstitutionalPresentation
    },
  }
</script>

<style lang="css" scoped>
  .grid-wrapper {
    display: grid;
    grid-template-columns: 340px calc(100% -340px);
  }

 .description {
   font-size: 15px;
   grid-column: 1 / 3;
   line-height: 24px;
   margin-left: 4%;
   max-width: 1000px;
 }

 .description b {
   font-size: 18px;
 }

 .description .capitalized {
   display: inline-box;
   font-size: 52px;
   font-weight: 100;
   line-height: 10px;
   position: relative;
   left: 10px;
 }

 .map {
   grid-column: 1 / 2;
   width: 100%;
 }

 .boards {
   grid-column: 2 / 3;
 }

.boards  b {
  font-size: 23px;
}

.boards .content-box {
  font-size: 18px;
  margin-top: 15px;
  padding: 20px 10px;
}

.content-box.blue {
  background-color: var(--blue);
  color: white;
}

.content-box.first {
  margin-left: 25px;
}

.content-box.second {
  color: var(--blue);
  font-weight: 600;
  margin-left: 120px;
  padding: 10px;
}

.content-box.third {
  border: 1px groove;
  font-size: 14px;
  margin-left: 20px;
}

.content-box.fourth {
  font-size: 14px;
}

@media screen and (max-width: 1020px) {
  .content-box.second {
    margin-left: 40px;
    padding: 10px;
  }

  .boards  b {
    font-size: 20px;
  }

  .boards .content-box {
    font-size: 16px;
  }

  .content-box.third,
  .content-box.fourth {
    font-size: 14px;
  }
}

@media screen and (max-width: 900px) {
  .grid-wrapper{
   display: block;
  }    
}
</style>