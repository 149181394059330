<template>
  <Section id="BairroSection" :title="$t('bairro-section.title')" img="./img/backgrounds/bg-bairro.jpg">
    <div>
      <span v-appear class="first" v-html="$t('bairro-section.p-1')"></span> <br />
      <div v-left  class="img">
        <Panel class="img" alt="Imagem Aérea do Bairro 300 Anos" img="./img/bairro/bairro300.JPG"/>
      </div>
      <span v-appear v-html="$t('bairro-section.p-2')"></span> <br />
      <hr>
      <p v-appear v-html="$t('bairro-section.p-3')"></p>
      <p v-appear class="quote" v-html=" $t('bairro-section.p-4')"></p>
    </div>
  </Section>
</template>

<script>
  import components from '@/components/global';
  const { Section, Panel } = components;

  export default {
    components: {
      Section,
      Panel
    },
  }
</script>

<style lang="css" scoped>
  #BairroSection {
    float: left;
  }

  #BairroSection .img {
    float: right;
    margin-top: 25px;
    margin-left: 15px;
    width: 50%;
  }

  #BairroSection span,
  #BairroSection p {
    font-size: 14px;
    line-height: 24px;
  }

  #BairroSection .quote {
    font-style: italic;
    line-height: 26px;
  }

  @media screen and (max-width: 900px) {
    #BairroSection {
      float: none;
    }

    #BairroSection .img {
      float: none;
      margin: 0;
      margin-top: 25px;
      margin-bottom: 25px;
      width: 100%;
    }

  }
  
</style>W

