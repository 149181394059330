<template>
  <div :class="propClass" class="NewsCard -gray-shadow">
    <h2 class="title">{{ title }}</h2>
    <img class="img" :src="img" :alt="alt" />
    <div class="data">
      <img src="@/assets/img/icons/lnr-calendar-full.svg" :alt=" $t('news-section.boards.icons.calendar')">
      <span>{{ date }}</span> 
    </div>
    <div class="data">
       <img src="@/assets/img/icons/lnr-bubble.svg" :alt="$t('news-section.boards.icons.chat')">
       <span>{{ department }}</span>
    </div>
    <p class="preview">{{ preview }}</p>
    <Button width="100%" @click="handleClick" class="button">{{ $t('news-section.boards.button') }}</Button>
  </div>
</template>

<script>
  import components from '@/components/global';
  const { Button } = components;

  export default {
    props: {
      title: {
        type: String,
        default: ""
      },
      img: {
        type: String,
        default: ""
      },
      alt: {
        type: String,
        default: ""
      },
      date: {
        type: String,
        default: ""
      },
      department: {
        type: String,
        default: ""
      },
      preview: {
        type: String,
        default: ""
      },
      article: {
        type: Number,
        default: 0
      },
      propClass: {
        type: Object,
        default: () => {}
      },
    },
    components: {
      Button,
    },
    methods: {
      handleClick() {
        this.$emit('open', this.article)
      }
    },
  }
</script>

<style lang="css" scoped>
  .NewsCard {
    width: 100%;
    padding: 15px;
  }

  .NewsCard .title {
    margin: 0;
    color: gray;
    font-family: 'Raleway', sans-serif;
  }

  .NewsCard .img {
    margin-top: 10px;
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .NewsCard .data {
    display: inline-flex;
    font-size: 14px;
    color: gray;
    align-items: center;
  }

  .NewsCard .data span {
    margin-left: 5px;
  }

  .NewsCard .data + .data {
    margin-left: 15px;
  }

  .NewsCard .preview {
    margin-top: 10px;
    font-size: 14px;
  }

  .NewsCard:focus,
  .NewsCard:hover {
	box-shadow: 0px 0px 30px 0px #999;
}

</style>