<template>
  <Section id="Article2" width="95%" :title="$t('news-section.boards.b-2.title')">
      <img :src="img" :alt="$t('news-section.boards.b-2.alt')">
      <p v-html="$t('news-section.boards.b-2.p-1')"></p>
  </Section>
</template>

<script>
  import components from '@/components/global'
  const { Section } = components
  export default {
    components: {
      Section,
    },
    data() {
      return {
        img: './img/news/premio-cat.jpg'
      }
    },
  }
</script>

<style lang="css" scoped>
  #Article2 img {
    width: 100%;
  }
</style>