<template>
  <img @click="translate" class="flag" :src="'./img/flags/' + img" :alt="$t('header.alts.lang')" />
</template>

<script>
  export default {
    data() {
      return {
        languages: ["pt", "en"]
      }
    },
    computed: {
      img() {
        return this.$i18n.locale === 'en' ? 'flagus.png' : 'flagbr.png'
      }
    },
    methods: {
      translate() {
        const index = this.languages.indexOf(this.$i18n.locale);
        const isLast = index + 1 >= this.languages.length;
        const newLang = isLast ? this.languages[0] : this.languages[index + 1]
        this.$i18n.locale = newLang
      }
    },
  }
</script>

<style lang="css" scoped>
  .flag {
    padding-left: 10px;
    transition: scale 0.2s ease-in-out;
  }
  .flag:focus, .flag:hover {
    cursor: pointer;
    scale: 1.1;
  }
</style>