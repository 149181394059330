import Vue from 'vue'
import App from '@/App.vue'
import i18n from '@/boot/i18n'
import apolloProvider from '@/boot/apollo'
import applyDirectives from '@/directives'

import '@/assets/css/normalize.css';
import '@/assets/css/global.css';
import '@/assets/css/fonts.css';
import '@/assets/css/directives.css';
import '@/assets/css/form.css';

Vue.config.productionTip = false

applyDirectives(Vue)

new Vue({
  el: '#app',
  i18n,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
