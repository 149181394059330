<template>
	<form @submit="handleSubmit" class="Form">
		<h3 class="title">{{ $t("contact-section.forms.contact") }}</h3>
		<div class="input-wrapper">
			<InputField
				v-model="form.name"
				:label="$t('contact-section.forms.name')"
			/>
			<InputField
				type="email"
				v-model="form.email"
				:label="$t('contact-section.forms.email')"
			/>
		</div>
		<div class="input-wrapper">
			<InputField
				required
				v-model="form.subject"
				:label="$t('contact-section.forms.subject')"
			/>
			<InputField v-model="form.file" type="file" />
		</div>

		<InputField
			required
			type="select"
			:options="[
				{
					name: $t('contact-section.forms.departments.label'),
					value: null,
					default: true,
				},
				{
					name: $t('contact-section.forms.departments.commercial'),
					value: 'commercial',
					default: false,
				},
				{
					name: $t('contact-section.forms.departments.quality'),
					value: 'quality',
					default: false,
				},
				{
					name: $t('contact-section.forms.departments.managment'),
					value: 'managment',
					default: false,
				},
				{
					name: $t('contact-section.forms.departments.hr'),
					value: 'hr',
					default: false,
				},
			]"
			:label="$t('contact-section.forms.departments.label')"
			v-model="form.department"
		/>

		<p class="warn">{{ $t("contact-section.forms.warn") }}</p>

		<InputField
			required
			type="textarea"
			v-model="form.message"
			:placeholder="$t('contact-section.forms.message')"
		/>

		<div class="submit-wrapper">
			<Button :disabled="sending" width="100px" propClass="submit">
				{{ $t("contact-section.forms.submit") }}
			</Button>
		</div>
		<p v-if="error" class="error">{{ $t("contact-section.forms.error") }}</p>
		<p v-else-if="success" v-appear class="success">
			{{ $t("contact-section.forms.success") }}
		</p>
		<p v-if="sending" class="sending">
			{{ $t("contact-section.forms.sending") }}
		</p>
	</form>
</template>

<script>
import components from "@/components/global";
const { InputField, Button } = components;

import gql from "graphql-tag";

export default {
	components: {
		InputField,
		Button,
	},
	data() {
		return {
			error: false,
			success: false,
			sending: false,
			fileRequired: false,
			form: {
				name: "",
				email: "",
				subject: "",
				file: null,
				department: null,
				message: "",
			},
		};
	},
	methods: {
		async handleSubmit(e) {
			e.preventDefault();
			const {
				name,
				email: from,
				subject,
				file,
				message: text,
				department: to,
			} = this.form;

			this.error = false;
			this.success = false;
			this.sending = true;

			try {
				await this.$apollo.mutate({
					mutation: gql`
						mutation(
							$name: String!
							$from: String!
							$subject: String!
							$file: Upload
							$text: String
							$to: String!
						) {
							sendMail(
								type: "contact"
								data: {
									name: $name
									from: $from
									subject: $subject
									file: $file
									text: $text
									to: $to
								}
							)
						}
					`,
					variables: {
						name,
						from,
						subject,
						file,
						text,
						to,
					},
					context: {
						useMultipart: true,
					},
				});
			} catch (err) {
				this.sending = false;
				this.error = true;
				return;
			}

			this.sending = false;
			this.success = true;
		},
	},
};
</script>

<style lang="css" scoped>
</style>