<template>
  <Section id="HistorySection" :title="$t('history-section.title')" img="./img/backgrounds/bg-historia.jpg" :subtitle="$t('history-section.subtitle')">
    <TimelineEvent float="right" year="2024" img='./img/timeline/2024.png' :alt="$t('history-section.timeline.2024.alt')">
      <template v-slot:event >
        <span v-html="$t('history-section.timeline.2024.title')"></span>
      </template>
      <template v-slot:text>
        <span v-html="$t('history-section.timeline.2024.body')"></span>
      </template>
    </TimelineEvent>

    <TimelineEvent float="left" year="2023" img='./img/timeline/2023.png' :alt="$t('history-section.timeline.2023.alt')">
      <template v-slot:event >
        <span v-html="$t('history-section.timeline.2023.title')"></span>
      </template>
      <template v-slot:text>
        <span v-html="$t('history-section.timeline.2023.body')"></span>
      </template>
    </TimelineEvent>

    <TimelineEvent float="right" year="2022" img='./img/timeline/2022.png' :alt="$t('history-section.timeline.2022.alt')">
      <template v-slot:event >
        <span v-html="$t('history-section.timeline.2022.title')"></span>
      </template>
      <template v-slot:text>
        <span v-html="$t('history-section.timeline.2022.body')"></span>
      </template>
    </TimelineEvent>

    <TimelineEvent float="left" year="2021" img='./img/timeline/timeline-2021-1.png' :alt="$t('history-section.timeline.2021.alt')">
      <template v-slot:event >
        <span v-html="$t('history-section.timeline.2021.title')"></span>
      </template>
      <template v-slot:text>
        <span v-html="$t('history-section.timeline.2021.body')"></span>
      </template>
    </TimelineEvent>

    <TimelineEvent float="right" year="2020" img='./img/timeline/timeline-2020-2.png' :alt="$t('history-section.timeline.2020-2.alt')">
      <template v-slot:event >
        <span v-html="$t('history-section.timeline.2020-2.title')"></span>
      </template>
      <template v-slot:text>
        <span v-html="$t('history-section.timeline.2020-2.body')"></span>
      </template>
    </TimelineEvent>

    <TimelineEvent float="left" year="2020" img='./img/timeline/timeline-2020-1.png' :alt="$t('history-section.timeline.2020.alt')">
      <template v-slot:event >
        <span v-html="$t('history-section.timeline.2020.title')"></span>
      </template>
      <template v-slot:text>
        <span v-html="$t('history-section.timeline.2020.body')"></span>
      </template>
    </TimelineEvent>

    <TimelineEvent float="right" year="2019" img='./img/timeline/gptw2019.jpg' alt="Equipe da empresa recebendo prêmio GPTW 2019">
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2019-2.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2019-2.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="left" year="2019" img='./img/timeline/2019.jpg' alt="Equipe da empresa recebendo prêmio Cateperpillar 2019" >
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2019.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2019.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="right" year="2018" img="./img/timeline/2018.jpg" alt="Foto tirada com drone dos funcionários da empresa">
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2018.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2018.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="left" year="2017" img='./img/timeline/2017.jpg' alt="Imagem aérea do prédio de pintura E-coat">
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2017.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2017.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="right" year="2016" img='./img/timeline/2016.jpg' alt="Imagem placa com o logo da Fundimisa">
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2016.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2016.body')"></span>
        </template>
    </TimelineEvent>

     <TimelineEvent float="left" year="2015" img='./img/timeline/2015.jpg' alt='Imagem segunda linha de moldagem'>
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2015.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2015.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="right" year="2013" img='./img/timeline/2013.jpg' alt="Imagem nova linha de moldagem">
      <template v-slot:event >
        <span v-html="$t('history-section.timeline.2013.title')"></span>
      </template>
      <template v-slot:text>
        <span v-html="$t('history-section.timeline.2013.body')"></span>
      </template>
    </TimelineEvent>

     <TimelineEvent float="left" year="2012" img='./img/timeline/2012.jpg' alt="Foto aérea da empresa 2012">
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2012.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2012.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="right" year="2011" img='./img/timeline/2011.jpg' alt="Foto aérea Fabrica 2">
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2011.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2011.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="left" year="2009" img='./img/timeline/2009.jpg' alt="Diretoria da empresa e funcionários em meio a obra recém iniciada da Fábrica 2">
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2009.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2009.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="right" year="2007" img='./img/timeline/2007.jpg' alt='Foto aérea prédio da usinagem terminado'>
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2007.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2007.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="left" year="2006" img='./img/timeline/2006.jpg' alt="Foto aérea terreno terraplanado para a construção do prédio da usinagem">
      <template v-slot:event >
          <span v-html="$t('history-section.timeline.2006.title')"></span>
        </template>
        <template v-slot:text>
        </template>
    </TimelineEvent>

    <TimelineEvent float="right" year="2004" img="./img/timeline/2004.jpg" alt="Foto aérea da empresa em 2004">
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.2004.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2004.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="left" year="2000" img="" alt="">
      <template v-slot:event >
          <span v-html="$t('history-section.timeline.2000.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.2000.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="left" year="1988">
        <template v-slot:event >
          <span v-html="$t('history-section.timeline.1988.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.1988.body')"></span>
        </template>
    </TimelineEvent>

    <TimelineEvent float="left" year="1980">
      <template v-slot:event >
          <span v-html="$t('history-section.timeline.1980.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.1980.body')"></span>
        </template>
    </TimelineEvent>

     <TimelineEvent float="left" year="1969" img='./img/timeline/1969.jpg' alt="Foto aérea da empresa em 1969" >
      <template v-slot:event >
          <span v-html="$t('history-section.timeline.1969.title')"></span>
        </template>
        <template v-slot:text>
          <span v-html="$t('history-section.timeline.1969.body')"></span>
        </template>
    </TimelineEvent>
  </Section>
</template>

<script>
  import components from '@/components/global';
  import TimelineEvent from './components/TimelineEvent'

  const { Section } = components;

  export default {
    components: {
      Section,
      TimelineEvent
    }
  }
</script>

<style lang="css" scoped>

  @media screen and (max-width: 1010px) {
    .subtitle {
      font-size: 12px;
      margin-top: 15px;
    }
  }
</style>