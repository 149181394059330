import Article1 from './01'
import Article2 from './02'
import Article3 from './03'
import Article4 from './04'


export default {
  Article1,
  Article2,
  Article3,
  Article4
}