import Button from './Button';
import Panel from './Panel';
import Section from './Section';
import PopUp from './PopUp';
import InputField from './InputField';

export default {
  Button,
  Panel,
  Section,
  PopUp,
  InputField
}