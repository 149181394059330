<template>
  <div class="Description">
    <div v-if="direction === 'right'" class="line" />
    <div :class="direction" class="wrapper">
     <p class="year">{{ year }}</p>
     <p class="event"> <slot name="event"/> </p>
     <hr />
     <p class="text"> <slot name="text"/> </p>
    </div>
    <div v-if="direction === 'left'" class="line"></div>
  </div>
</template>

<script>
  export default {
    props: {
      year: {
        type: String,
        default: ""
      },
      direction: {
        type: String,
        default: 'left'
      }
    }
  }
</script>

<style lang="css" scoped>
  .Description {
    align-items: center;
    display: flex;
  }

  .Description .line {
    background-color: var(--blue);
    height: 3px;
    width: 6%;
  }

  .wrapper {
    display: table;
    margin: auto;
    padding-bottom: 20px;
    position: relative;
    width: 94%;
    z-index: 1;
  }
  .wrapper.right {
    padding-left: 20px; 
  }
  .wrapper.left {
    padding-right: 20px; 
  }

  .wrapper::before {
    bottom  : 0;
    border: 3px groove var(--blue);
    border-top: 0;
    content : "";
    height  : 90%;
    position: absolute;
    width   : 85%;
  }
  .wrapper.right::before {
    border-right: 0;
    left: 0;
  }
  .wrapper.left::before {
    border-left: 0;
    right: 0;
  }

  .wrapper p {
    margin: 0;
  }

  .wrapper hr {
    border-top: 1px dotted lightgray;
    border-bottom: 0;
    margin-top: 13px;
  }

  .wrapper .year,
  .wrapper .event {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
  }

  .wrapper .year {
    font-size: 20px;
    font-weight: 600;
  }

  .wrapper .event {
    font-size: 18px;
    margin-top: 8px;
  }

  .wrapper .text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 13px;
  }

  
</style>