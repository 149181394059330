<template>
	<div>
		<img
			class="burger-icon"
			src="@/assets/img/icons/menu.svg"
			:alt="() => $t('nav-bar.alts.menu')"
			@click="handleOpen"
		/>
		<transition v-move appear name="toggle">
			<div v-if="isOpen" class="panel-wrapper -light-shadow">
				<ul @blur="onBlur" class="nav-list">
					<slot />
				</ul>
				<LangButton />
			</div>
		</transition>
	</div>
</template>

<script>
import LangButton from "./LangButton";
export default {
	components: {
		LangButton,
	},
	data() {
		return {
			isOpen: false,
		};
	},
	methods: {
		handleOpen() {
			this.isOpen = !this.isOpen;
		},
		onBlur() {
			this.isOpen = false
		}
	},
};
</script>

<style lang="css" scoped>
.burger-icon {
	height: 20px;
	position: absolute;
	right: 15px;
	top: 10px;
	transition: all 0.2s ease-in-out;
	width: 25px;
	float: right;
}
.burger-icon:hover,
.burger-icon:focus {
	cursor: pointer;
	opacity: 0.8;
	scale: 1.2;
}

.panel-wrapper {
	background-color: white;
	border-radius: 0 0 8px 8px;
	left: 0;
	padding-bottom: 10px;
	position: absolute;
	top: 40px;
	width: 100%;
}

.nav-list {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	list-style: none;
	padding: 0;
}


</style>