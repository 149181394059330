import NavMenu from './NavMenu'
import SubMenu from './SubMenu'
import NavItem from './NavItem'
import LangButton from './LangButton'
import BurgerMenu from './BurgerMenu'

export default {
  NavMenu,
  SubMenu,
  NavItem,
  LangButton,
  BurgerMenu
}