const directiveNames = [
  'appear', 
  'grow', 
  'up', 
  'down', 
  'left', 
  'right',  
  'slow-left', 
  'slow-right'
];

const applyDirectives = (Vue) => {
  directiveNames.forEach((directive) => {
    Vue.directive(directive, {
      inViewport (el) {
        const rect = el.getBoundingClientRect()
        return !(rect.bottom < 0 || rect.right < 0 || 
                 rect.left > window.innerWidth ||
                 rect.top > window.innerHeight)
      },
    
      bind(el, binding) {
        el.classList.add(directive + '-before-enter')
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            el.classList.add(directive + '-enter')
            el.classList.remove(directive + '-before-enter')
            binding.def.unbind(el, binding)        
          }
        }
        document.addEventListener('scroll', el.$onScroll)
      },
    
      inserted(el) {
        el.$onScroll()  
      },
    
      unbind(el) {    
        document.removeEventListener('scroll', el.$onScroll)
        delete el.$onScroll
      }
    })
  })
}

export default applyDirectives