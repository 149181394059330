var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"Form",on:{"submit":_vm.handleSubmit}},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("contact-section.forms.contact")))]),_c('div',{staticClass:"input-wrapper"},[_c('InputField',{attrs:{"label":_vm.$t('contact-section.forms.name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('InputField',{attrs:{"type":"email","label":_vm.$t('contact-section.forms.email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('div',{staticClass:"input-wrapper"},[_c('InputField',{attrs:{"required":"","label":_vm.$t('contact-section.forms.subject')},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}}),_c('InputField',{attrs:{"type":"file"},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})],1),_c('InputField',{attrs:{"required":"","type":"select","options":[
			{
				name: _vm.$t('contact-section.forms.departments.label'),
				value: null,
				default: true,
			},
			{
				name: _vm.$t('contact-section.forms.departments.commercial'),
				value: 'commercial',
				default: false,
			},
			{
				name: _vm.$t('contact-section.forms.departments.quality'),
				value: 'quality',
				default: false,
			},
			{
				name: _vm.$t('contact-section.forms.departments.managment'),
				value: 'managment',
				default: false,
			},
			{
				name: _vm.$t('contact-section.forms.departments.hr'),
				value: 'hr',
				default: false,
			} ],"label":_vm.$t('contact-section.forms.departments.label')},model:{value:(_vm.form.department),callback:function ($$v) {_vm.$set(_vm.form, "department", $$v)},expression:"form.department"}}),_c('p',{staticClass:"warn"},[_vm._v(_vm._s(_vm.$t("contact-section.forms.warn")))]),_c('InputField',{attrs:{"required":"","type":"textarea","placeholder":_vm.$t('contact-section.forms.message')},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('div',{staticClass:"submit-wrapper"},[_c('Button',{attrs:{"disabled":_vm.sending,"width":"100px","propClass":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("contact-section.forms.submit"))+" ")])],1),(_vm.error)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t("contact-section.forms.error")))]):(_vm.success)?_c('p',{directives:[{name:"appear",rawName:"v-appear"}],staticClass:"success"},[_vm._v(" "+_vm._s(_vm.$t("contact-section.forms.success"))+" ")]):_vm._e(),(_vm.sending)?_c('p',{staticClass:"sending"},[_vm._v(" "+_vm._s(_vm.$t("contact-section.forms.sending"))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }