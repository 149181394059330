<template>
	<div :style="style" class="Certification -gray-shadow">
		<div class="icon">
			<img :src="icon" :alt="alt" />
		</div>
		<p class="name">{{ name }}</p>
		<p class="description">{{ description }}</p>
		<a :href="document" target="_blank" class="button">
			<Button>
				{{ $t("quality-section.certifications.button") }}
			</Button>
		</a>
	</div>
</template>

<script>
import components from "@/components/global";

const { Button } = components;

export default {
	components: {
		Button,
	},
	methods: {
		handleOpenCertification() {
			this.$emit("open", { title: this.name, document: this.document });
		},
	},
	props: {
		width: {
			type: String,
			default: "100%",
		},
		img: {
			type: String,
			default: "",
		},
		icon: {
			type: String,
			default: "./img/icons/lnr-checkmark-circle.svg",
		},
		alt: {
			type: String,
			default: "Icone checkbox",
		},
		name: {
			type: String,
			default: "",
		},
		description: {
			type: String,
			default: "",
		},
		document: {
			type: String,
			default: "",
		},
	},
	computed: {
		style() {
			return {
				width: this.width,
				background: "url(" + this.img + ") center",
			};
		},
	},
};
</script>

<style lang="css" scoped>
.Certification {
	height: 320px;
	transition: box-shadow 0.3s ease;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: flex-start;
}

.Certification:focus,
.Certification:hover {
	box-shadow: 0px 0px 30px 0px #999;
}

.Certification .icon {
	border: 1px groove var(--blue);
	padding: 20px;
	border-radius: 10px;
	margin-top: 50px;
}

.Certification .icon img {
	width: 30px;
}

.Certification .name {
	margin: 0;
	margin-top: 30px;
	font-size: 16px;
	font-family: "Roboto", sans-serif;
}

.Certification .description {
	margin: 0;
	font-size: 24px;
	text-align: center;
	font-family: "Roboto", sans-serif;
	margin-top: 5px;
}

.Certification button {
	text-transform: uppercase;
	margin-top: 2rem;
}
</style>