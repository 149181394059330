<template>
	<div id="app">
		<Header />
		<WelcomeSection />
		<CompanySection />
		<HistorySection />
		<AwardsSection />
		<TalentsSection />
		<BairroSection />
		<SustentabilitySection />
		<QualitySection />
		<NewsSection />
		<ContactSection />
		<FooterSection />
	</div>
</template>

<script>
import components from '@/components/sections'

export default {
	name: "App",
	components
};
</script>

<style>

#app {
	background-color: #474747;;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	height: 100vh;
}

hr {
	border-top: 1px dotted lightgray;
	border-bottom: 0;
	margin-top: 20px;
	margin-bottom: 20px;
}

p {
	font-size: 15px;
	line-height: 24px;
	margin-top: 20px;
	font-weight: 400;
	font-family: 'Raleway', sans-serif;
}

</style>
