<template>
  <Section id="Article1" width="95%" :title="$t('news-section.boards.b-1.title')">
    <div>
      <img :src="img" :alt="$t('news-section.boards.b-1.alt')">
      <p v-html="$t('news-section.boards.b-1.p-1')"></p>
    </div>
      <p v-html="$t('news-section.boards.b-1.p-2')"></p>
      <p v-html="$t('news-section.boards.b-1.p-3')"></p>
  </Section>
</template>

<script>
  import components from '@/components/global'
  const { Section } = components
  export default {
    components: {
      Section,
    },
    data() {
      return {
        img: './img/news/gptwrs2018.jpg'
      }
    },
  }
</script>

<style lang="css" scoped>
  #Article1 div {
    float: right;
  }

  #Article1 img {
    width: 60%;
    float: left;
    margin: 20px 20px 5px 0px;
  }

  @media screen and (max-width: 900px) {
    #Article1 img {
      width: 100%;
      float: none;
      margin: 0;
    }
  }
</style>